import React, { useState, useEffect } from "react"
import { useMsal, useAccount } from "@azure/msal-react";
import './App.css';

function App() {
  const { instance, accounts, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState();
  const [apiResult, setApiResult] = useState();
  const account = useAccount(accounts[0] || {});
  const [error, setError] = useState();

  useEffect(() => {
    getAccessToken()
  }, [account, instance]);

  useEffect(()=>{
    apiCall()
  }, [accessToken])

  const getAccessToken = () => {
    if (account) {
      instance.acquireTokenSilent({
        scopes: ["dba4fad6-32fe-4a23-a012-322433f6afbf"],
        account: account
      }).then((response) => {
        if (response) {
          setAccessToken(response.accessToken);
        }
      });
    }
  }

  const apiCall = () => {
    if (accessToken) {
      fetch('/api/test', {
        headers: {Authorization: `Bearer ${accessToken}`}
      })
        .then(resp => resp.json())
        .then(json => setApiResult(JSON.stringify(json)))
    }
  }

  if (error)
      return <div>ERROR: {error}</div>
  if (accounts.length > 0) {
    return <div>
      <p>There are currently {accounts.length} users signed in! <button onClick={() => instance.logout()}>Logout</button></p>
      <h3>User info</h3>
      {account && <pre>{JSON.stringify(account, null, 2)}</pre>}
      <br />
      <h3>Access token</h3>
      <button onClick={() => getAccessToken()}>Obtain</button>
      {accessToken && <pre>{accessToken}</pre>}
      <br />
      <h3>API access result</h3>
      <button onClick={() => apiCall()}>Call</button>
      {apiResult && <pre>{apiResult}</pre>}
      <br />
    </div>
  } else if (inProgress === "login") {
    return <span>Login is currently in progress!</span>
  } else {
    return (
      <>
        <p>There are currently no users signed in!</p>
        <button onClick={() => {
          setError(undefined)
          instance.loginRedirect().catch(function(error) {
            setError(JSON.stringify(error));
          })
        }}>Login</button>
      </>
    );
  }
}

export default App;
